import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { getLandingPage } from '../../services/homeService';
import AdsSlider from '../../components/slider/adsSlider';
import { SwiperSlide } from 'swiper/react';
import FireWheel from '../../components/fireWheel';
import HottestWheels from '../../components/hottestWheels';
import BigWin from '../../components/bigWin';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';

export function Home() {
	const [loading, setLoading] = useState(false);
	const [home, setHome] = useState(null);
	const { t } = useTranslation();
	const getHome = async () => {
		setLoading(true);
		const response = await getLandingPage();
		if (response.status === 200) {
			setHome(response.data);
		}
		setLoading(false);
	};
	useEffect(() => {
		getHome();
	}, []);

	if (home && !loading) {
		return (
			<section className={styles['home']}>
				{home.ads.length > 0 && (
					<AdsSlider slidesPerView={1}>
						{home.ads.map((ad) => (
							<SwiperSlide key={ad.id}>
								<img src={ad.image} alt='ad' className={styles['home-ad-image']} />
							</SwiperSlide>
						))}
					</AdsSlider>
				)}
				{home.partners?.length > 0 && (
					<div className={styles['home-partners']}>
						<h3>
							{t('partners')}
							<span>{t('my_profile.redeem_now')}</span>
						</h3>
						<div className={styles['home-partners-container']}>
							{home.partners.map((partner) => (
								<img src={partner.logo} alt={partner.slug} className={styles['home-partners-image']} />
							))}
						</div>
					</div>
				)}
				{home.fire_wheel && <FireWheel product={home.fire_wheel} />}
				{home.hottest_wheels.length > 0 && <HottestWheels products={home.hottest_wheels} />}
				{home.recent_big_wons.length > 0 && <BigWin wonBig={home.recent_big_wons} />}
			</section>
		);
	} else {
		return <Loader />;
	}
}

export default Home;
