import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import redeemIcon from '../../assets/icons/redeem.svg';

const VoucherCard = ({ voucher }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleGoToRedemption = () => {
		navigate(`/redemption/${voucher.id}`, { state: { voucher } });
	};
	return (
		<div onClick={handleGoToRedemption} className={styles['voucher-card']}>
			<p className={styles['redeem']}>
				{t('my_profile.redeem')}
				<span>{`${Number(voucher.amount)} ${t('currency')}`}</span>
			</p>
			<div className={styles['voucher-card-image-container']}>
				<img className={styles['voucher-card-image']} src={voucher.image} alt={voucher.title} />
			</div>
			<h3 className={styles['voucher-card-title']}>{t('my_profile.redeem_now')}</h3>
			<p className={styles['voucher-card-text']}>{voucher.title}</p>
			<p className={styles['voucher-card-redeem']}>
				<img src={redeemIcon} alt='' />
				{t('redeem_offer', { count: voucher.redeem_count })}
			</p>
			<div className={styles['voucher-card-brand-container']}>
				<img className={styles['voucher-card-brand']} src={voucher.vendor.logo} alt={voucher.vendor.name} />
			</div>
		</div>
	);
};

export default VoucherCard;
