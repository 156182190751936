import { useRef } from 'react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import prevArrow from '../../assets/icons/adSliderPrevArrow.svg';
import nextArrow from '../../assets/icons/adSliderNextArrow.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
// import 'swiper/css/pagination';
import './index.css';

export function AdsSlider({ children, slidesPerView }) {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	return (
		<div style={{ position: 'relative' }}>
			<Swiper
				// install Swiper modules
				modules={[Navigation, Pagination, A11y, Autoplay]}
				slidesPerView={slidesPerView || 'auto'}
				loop={true}
				autoplay={{
					delay: 2000,
				}}
				speed={1000}
				spaceBetween={20}
				className='ads-slider'
				navigation={{
					prevEl: navigationPrevRef?.current,
					nextEl: navigationNextRef?.current,
				}}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef?.current;
					swiper.params.navigation.nextEl = navigationNextRef?.current;
				}}
				pagination={{ clickable: true }}>
				{children}
				<div className='prev-button' ref={navigationPrevRef}>
					<img src={prevArrow} alt='previous arrow' />
				</div>
				<div className='next-button' ref={navigationNextRef}>
					<img src={nextArrow} alt='next arrow' />
				</div>
			</Swiper>
		</div>
	);
}

export default AdsSlider;
