import styles from './index.module.scss';
import Button from '../formButton';
import qualified from '../../assets/images/qualified.gif';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import Slider from '../slider';
import { SwiperSlide } from 'swiper/react';
import Ticket from '../ticket';
import { useState } from 'react';

const colors = ['#a51d5d', '#f49c26', '#b46926', '#ed7443', '#b25237', '#e95c54', '#ab324a', '#e13a6c'];

export function QualifiedModal({ onModalSubmit, ticket_won, iteration, streakToWin }) {
	const { t, i18n } = useTranslation();
	const [selectedTickets, setSelectedTickets] = useState([]);
	const toogleSelectTicket = (num) => {
		if (selectedTickets.includes(num)) {
			setSelectedTickets(selectedTickets.filter((ticket) => ticket !== num));
		} else {
			setSelectedTickets([num]);
		}
	};
	const getWheelIteration = (iteration) => {
		if (iteration + 1 === streakToWin) {
			return i18n.language === 'en-US' ? `Final` : 'النهائي';
		}
		switch (iteration) {
			case 0:
				return i18n.language === 'en-US' ? `Second` : 'ثاني';
			case 1:
				return i18n.language === 'en-US' ? `Second` : 'ثاني';
			case 2:
				return i18n.language === 'en-US' ? `Third` : 'ثالث';
			case 3:
				return i18n.language === 'en-US' ? `Fourth` : 'رابع';
			case 4:
				return i18n.language === 'en-US' ? `Fifth` : 'خامس';
			case 5:
				return i18n.language === 'en-US' ? `Sixth` : 'سادس';
			default:
				return i18n.language === 'en-US' ? `${iteration - 1}th` : `${iteration - 1}`;
		}
	};
	return (
		<>
			<Confetti width={window.innerWidth} colors={colors} initialVelocityY={20} tweenDuration={1000} numberOfPieces={100} />
			<div className={styles['modal']}>
				<div className={styles['modal-container']}>
					<img src={qualified} alt='qualified' className={styles['modal-image']} />
					<div className={styles['modal-content']}>
						<p className={styles['modal-text']}>
							{`${t('ticket')}`} {ticket_won} {t('live_wheel.can_win')}
						</p>
						<div className={styles['slider']}>
							<p className={styles['slider-title']}>{t('live_wheel.select_tickets_now', { iteration: getWheelIteration(iteration) })}</p>
							<Slider>
								{Array.from({ length: 8 }, (_, i) => i + 1).map((num) => {
									return (
										<SwiperSlide key={num}>
											<Ticket selectedTickets={selectedTickets} number={num} toogleSelectTicket={toogleSelectTicket} />
										</SwiperSlide>
									);
								})}
							</Slider>
						</div>
						<Button
							handleSubmit={() => onModalSubmit(selectedTickets)}
							text={
								iteration + 1 === streakToWin ? t('live_wheel.spin_final_wheel') : t('live_wheel.spin', { iteration: getWheelIteration(iteration) })
							}
							type={'button'}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default QualifiedModal;
